import * as React from "react";
import parse from "html-react-parser";

import { ProductContainer } from "@src/components";

export type ProductHtmlBlockProps = {
  html?: string | null;
};

export function ProductHtmlBlock({ html }: ProductHtmlBlockProps) {
  if (!html) {
    return null;
  }
  return (
    <div className="my-10 md:my-16 lg:my-24">
      <ProductContainer>
        <div>{parse(html)}</div>
      </ProductContainer>
    </div>
  );
}

export default ProductHtmlBlock;
