import * as React from "react";
import parse from "html-react-parser";

export type HtmlBlockProps = {
  html?: string | null;
};

export function HtmlBlock({ html }: HtmlBlockProps) {
  if (!html) {
    return null;
  }
  return (
    <div className="my-8 md:my-16">
      <div className="prose md:prose-lg">{parse(html)}</div>
    </div>
  );
}

export default HtmlBlock;
