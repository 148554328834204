import * as React from "react";
import {
  ContentfulImage,
  ProductContainer,
  RichText,
  SmartLink
} from "@src/components";

import { Document } from "@contentful/rich-text-types";
import { Disclosure } from "@headlessui/react";
import { IContentfulImage } from "@src/lib/types";
import { PricingModules } from "@src/lib/services/server/contentful";
import { ContentfulImageUrlTransforms } from "@src/lib/utils";

export type PricingGridBlockProps = {
  heading?: string | null;
  mainCtaTitle?: string | null;
  mainCtaUrl?: string | null;
  includedHeading?: string | null;
  includedDescription?: string | null;
  modulesTitle?: string | null;
  modulesSubheading?: string | null;
  items: PricingModules[] | null | undefined;
  leftCtaTitle?: string | null;
  leftCtaUrl?: string | null;
  rightCtaTitle?: string | null;
  rightCtaUrl?: string | null;
  popularAddOns?: Document | null;
  image?: IContentfulImage | null;
};

export function PricingGridBlock({
  heading,
  mainCtaTitle,
  mainCtaUrl,
  includedHeading,
  includedDescription,
  modulesTitle,
  modulesSubheading,
  items,
  leftCtaTitle,
  leftCtaUrl,
  rightCtaTitle,
  rightCtaUrl,
  popularAddOns,
  image
}: PricingGridBlockProps) {
  const imageTransforms: ContentfulImageUrlTransforms = {
    width: image?.width || 180,
    height: image?.height || 355
  };

  return (
    <div className="bg-pr-yellow-200">
      <ProductContainer>
        <div className="flex flex-col lg:flex-row lg:space-x-[75px]">
          <div className="lg:pricing-shadow -mx-5 flex flex-col space-y-3.5 bg-white px-[30px] py-10 lg:mx-0 lg:w-[65%] lg:rounded-3xl lg:p-[50px]">
            <div className="mb-2 text-center text-[30px] font-medium">
              {heading}
            </div>

            {mainCtaUrl && mainCtaTitle && (
              <SmartLink
                href={mainCtaUrl}
                className={
                  "pr-button pr-button-lg pr-button-teal mx-auto w-fit"
                }
              >
                {mainCtaTitle}
              </SmartLink>
            )}

            {includedHeading && (
              <div className="space-y-4 border-b border-[#0000004f] pb-[14px] pt-10 lg:pt-0">
                <div className="flex flex-row items-center justify-between">
                  <div className="text-[30px]">{includedHeading}</div>
                  <div className="text-xl">Included</div>
                </div>
                {includedDescription && (
                  <div className="ml-5 max-w-[206px] text-pr-base font-bold md:max-w-[306px] lg:ml-10 lg:max-w-[421px]">
                    {includedDescription}
                  </div>
                )}
              </div>
            )}

            <div className="">
              <div className="flex flex-row items-center justify-between">
                {modulesTitle && (
                  <div className="text-[30px]">{modulesTitle}</div>
                )}
                {modulesSubheading && (
                  <div className="text-2xs">{modulesSubheading}</div>
                )}
              </div>

              {items && (
                <div className="lg:ml-10">
                  {items.map((item, index) => (
                    <div key={index}>
                      <div className="lg:hidden">
                        <Disclosure>
                          {({ open }) => (
                            <div className="group relative" key={index}>
                              <Disclosure.Button className="flex w-full flex-col border-b border-[#0000004f] px-2.5 py-[18px] group-hover:bg-gray-200">
                                <div className="flex w-full flex-row items-center justify-between">
                                  <div className="flex flex-row">
                                    {item.heading && (
                                      <div className="text-pr-base font-bold">
                                        {item.heading}
                                      </div>
                                    )}
                                    <div className="text-[10px] font-bold text-[#B0B0B0]">
                                      {" "}
                                      ?{" "}
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center gap-9">
                                    {item.previousPrice && (
                                      <div className="text-xl text-pr-dark-gray-300">
                                        <span className="strikethrough-card">
                                          {item.previousPrice}
                                        </span>
                                      </div>
                                    )}
                                    {!item.previousPrice && item.price && (
                                      <div className="flex text-xl">
                                        {item.price}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {item.percentOff && (
                                  <div className="flex w-full flex-row items-end justify-between pt-2">
                                    <div className="pl-2.5 font-sans text-pr-base font-bold text-pr-red-500">
                                      {item.percentOff}
                                    </div>
                                    {item.price && (
                                      <div className="flex text-xl">
                                        {item.price}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Disclosure.Button>
                              {item.description && (
                                <Disclosure.Panel className="pricing-shadow absolute bottom-[80%] left-1/2 z-10 hidden w-full max-w-[350px] -translate-x-1/2 rounded-3xl bg-white p-8 group-hover:block">
                                  {item.description}
                                </Disclosure.Panel>
                              )}
                            </div>
                          )}
                        </Disclosure>
                      </div>
                      <div className="hidden lg:block">
                        <div className="group relative" key={index}>
                          <div className="flex w-full flex-col border-b border-[#0000004f] px-2.5 py-[18px] group-hover:bg-gray-200">
                            <div className="flex w-full flex-row items-center justify-between">
                              <div className="flex flex-row">
                                {item.heading && (
                                  <div className="text-pr-base font-bold">
                                    {item.heading}
                                  </div>
                                )}
                                <div className="text-[10px] font-bold text-[#B0B0B0]">
                                  {" "}
                                  ?{" "}
                                </div>
                              </div>
                              <div className="flex flex-row items-center gap-9">
                                {item.previousPrice && (
                                  <div className="text-xl text-pr-dark-gray-300">
                                    <span className="strikethrough-card">
                                      {item.previousPrice}
                                    </span>
                                  </div>
                                )}
                                {!item.previousPrice && item.price && (
                                  <div className="flex text-xl">
                                    {item.price}
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.percentOff && (
                              <div className="flex w-full flex-row items-end justify-between pt-2">
                                <div className="pl-2.5 font-sans text-pr-base font-bold text-pr-red-500">
                                  {item.percentOff}
                                </div>
                                {item.price && (
                                  <div className="flex text-xl">
                                    {item.price}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {item.description && (
                            <div className="pricing-shadow absolute bottom-[80%] left-1/2 z-10 hidden w-full max-w-[450px] -translate-x-1/2 text-ellipsis rounded-3xl bg-white p-10 group-hover:block">
                              {item.description}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex flex-row items-center justify-between pt-6 lg:ml-10 lg:pt-8">
                {leftCtaUrl && leftCtaTitle && (
                  <SmartLink
                    href={leftCtaUrl}
                    className={
                      "link-underline-animated-sm text-pr-base font-bold text-pr-teal-600"
                    }
                  >
                    {leftCtaTitle}
                  </SmartLink>
                )}

                {rightCtaUrl && rightCtaTitle && (
                  <SmartLink
                    href={rightCtaUrl}
                    className={
                      "link-underline-animated-sm text-xl lg:text-pr-base lg:font-bold"
                    }
                  >
                    {rightCtaTitle}
                  </SmartLink>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-10 lg:w-[30%] lg:pt-[50px]">
            {popularAddOns && (
              <div className="pricing-table pb-[20px] lg:pb-[35px] [&_h3]:text-[30px]">
                <RichText text={popularAddOns} />
              </div>
            )}
            <div>
              {image && (
                <figure className="mx-auto">
                  <img src={image.url || ""} alt={image.description || ""} />
                </figure>
              )}
            </div>
            <div className="mx-auto pt-[30px] lg:pt-[50px]">
              {mainCtaUrl && mainCtaTitle && (
                <SmartLink
                  href={mainCtaUrl}
                  className={
                    "pr-button pr-button-lg pr-button-teal mx-auto w-fit"
                  }
                >
                  {mainCtaTitle}
                </SmartLink>
              )}
            </div>
          </div>
        </div>
      </ProductContainer>
    </div>
  );
}

export default PricingGridBlock;
