/* eslint-disable @next/next/next-script-for-ga */
import * as React from "react";
import Head from "next/head";
import { contentfulEntryUrl } from "@src/lib/utils";
import Image, { ImageProps } from "next/image";
import { IContentfulImage } from "@src/lib/types";
import {
  contentfulImageUrl,
  ContentfulImageUrlTransforms
} from "@src/lib/utils";
import getConfig from "next/config";
import { NextConfig } from "@src/lib/types";
import Script from "next/script";

const { publicRuntimeConfig } = getConfig() as NextConfig;

export type SeoEntry = {
  __typename?: string | null;
  title?: string | null;
  description?: string | null;
  leadImage?: {
    __typename?: "Asset";
    description?: string | null;
    url?: string | null;
  } | null;
  seoTitle?: string | null;
  seoDescription?: string | null;
  seoImage?: {
    __typename?: "Asset";
    description?: string | null;
    url?: string | null;
  } | null;
  slug?: string | null;
};

export type SeoProps = {
  entry?: SeoEntry;
  pageNumber?: number;
};

export function Seo({ entry, pageNumber }: SeoProps) {
  // console.log("entry: ", entry);
  const dimensions = {
    width: 1200,
    height: 627
  };

  const transforms: ContentfulImageUrlTransforms = {
    fit: "fill" as const,
    focus: "center" as const,
    format: "webp" as const,
    quality: 50,
    width: dimensions.width,
    height: dimensions.height
  };

  const title = entry?.seoTitle
    ? entry.seoTitle
    : entry?.title
    ? entry.title
    : null;
  const description = entry?.seoDescription
    ? entry.seoDescription
    : entry?.description
    ? entry.description
    : null;
  const image = entry?.seoImage
    ? entry.seoImage
    : entry?.leadImage
    ? entry.leadImage
    : null;
  const url = entry ? contentfulEntryUrl(entry, true) : null;

  const entryTitle: string | null = entry?.title
    ? `${entry.title} | BentoBox${pageNumber ? ` - Page ${pageNumber}` : ""}`
    : null;
  const seoTitle: string | null = title
    ? `${title} | BentoBox${pageNumber ? ` - Page ${pageNumber}` : ""}`
    : null;
  const seoDescription: string | null = description;
  // const seoImage: string | null = image
  //   ? contentfulImageUrl(image as IContentfulImage, transforms)
  //   : "https://images.ctfassets.net/h81st780aesh/31B4LHi6ib0YbUFq6Uqr8q/3130756c2cd010c534de70cd66c03e00/Getbento_Preview_final.jpg";
  const seoImage: string | null | undefined = image
    ? image.url
    : "https://images.ctfassets.net/h81st780aesh/31B4LHi6ib0YbUFq6Uqr8q/3130756c2cd010c534de70cd66c03e00/Getbento_Preview_final.jpg";
  const entryUrl: string | null = url;

  const gtmEnabled: string = publicRuntimeConfig.gtm.enabled;
  const gtmId: string = publicRuntimeConfig.gtm.id;

  return (
    <Head>
      {seoTitle && <title>{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {entryUrl && <meta content={entryUrl} property="og:url" />}
      <meta content="website" property="og:type" />
      {seoTitle && <meta content={seoTitle} property="og:title" />}
      {seoDescription && (
        <meta content={seoDescription} property="og:description" />
      )}
      {seoImage && (
        <>
          <meta content={seoImage} property="og:image" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bentoboxnyc" />
        </>
      )}
      {entryUrl && <link rel="canonical" href={entryUrl} />}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        type="image/png"
        href="https://media-cdn.getbento.com/accounts/c831ea07f62fba23e7ea35e6b0f1c013/media/images/91355favicon.png?v=1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://media-cdn.getbento.com/accounts/c831ea07f62fba23e7ea35e6b0f1c013/media/images/91355favicon.png?v=1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://media-cdn.getbento.com/accounts/c831ea07f62fba23e7ea35e6b0f1c013/media/images/91355favicon.png?v=1"
      />

      {gtmEnabled === "true" && gtmId && (
        <Script id="gtm-script">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmId}');
          `}
        </Script>
      )}
    </Head>
  );
}

export default Seo;
