import * as React from "react";
import parse from "html-react-parser";
import { Document } from "@contentful/rich-text-types";
import { ProductContainer } from "@src/components";

export type TrendReportOneColumnTextProps = {
  heading?: string | null;
  eyebrow?: string | null;
  headingStyle?: string | null;
  headingTag?: string | null;
  html?: string | null;
};

const returnLineBreaks = (text: string) => {
  if (text.includes("\n")) {
    return text.split("\n").map((innerText: string, i: number) => {
      return (
        <span key={i} className="block">
          {innerText}
        </span>
      );
    });
  } else {
    return text;
  }
};

// function Heading({
//   heading,
//   headingTag
// }: TrendReportOneColumnTextProps) {
//   return (
//     <h1 className={`type-pr-alpha lg:text-pr-12xl ${headingClassName}`}>
//        {returnLineBreaks(heading)}
//     </h1>
//   );
// }

export function TrendReportOneColumnText({
  heading,
  eyebrow,
  headingStyle,
  headingTag = "h1",
  html
}: TrendReportOneColumnTextProps) {
  const defaultHeadingStyle: string = "black";

  const headingClassNames: { [key: string]: string } = {
    red: "text-pr-red-500",
    green: "text-pr-green-500",
    yellow: "text-pr-yellow-500",
    teal: "text-pr-teal-500",
    black: "text-pr-dark-gray-500"
  };

  const headingClassName =
    headingClassNames[headingStyle ? headingStyle : defaultHeadingStyle];

  return (
    <ProductContainer>
      <div className="flex flex-col items-center">
        <div className="space-y-5 text-center md:space-y-8 lg:max-w-[1168px]">
          {eyebrow && (
            <div className="text-pr-sm text-pr-dark-gray-400 lg:text-pr-xl">
              {returnLineBreaks(eyebrow)}
            </div>
          )}
          {heading && headingTag && headingTag == "h1" && (
            <h1 className={`type-pr-alpha lg:text-pr-12xl ${headingClassName}`}>
              {returnLineBreaks(heading)}
            </h1>
          )}
          {heading && headingTag && headingTag == "h2" && (
            <h2
              className={`type-pr-beta lg:text-[2.75rem] ${headingClassName}`}
            >
              {returnLineBreaks(heading)}
            </h2>
          )}
          {heading && headingTag && headingTag == "h3" && (
            <h3 className={`type-pr-delta font-bold ${headingClassName}`}>
              {returnLineBreaks(heading)}
            </h3>
          )}
          {html && (
            <div className="text-pr-base leading-pr-looser text-pr-dark-gray-500 lg:text-pr-xl">
              {parse(html)}
            </div>
          )}
        </div>
      </div>
    </ProductContainer>
  );
}

export default TrendReportOneColumnText;
