/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Modal, Seo, SeoEntry, Stylesheet } from "@src/components";
import getConfig from "next/config";
import { NextConfig } from "@src/lib/types";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { keyMapping, useUrlHashActions } from "@src/lib/utils";
import Head from "next/head";
import { ModalsQuery } from "@src/lib/services/server/contentful/generated/graphqlSdk";
import Script from "next/script";

const { publicRuntimeConfig } = getConfig() as NextConfig;

export type LayoutProps = {
  entry?: SeoEntry;
  pageNumber?: number;
  modals?: ModalsQuery;
  children: React.ReactNode;
};

export function Layout({ entry, pageNumber, modals, children }: LayoutProps) {
  const router = useRouter();
  const cookies = parseCookies();
  const queryParsed = router.query;
  const gtmEnabled: string = publicRuntimeConfig.gtm.enabled;
  const gtmId: string = publicRuntimeConfig.gtm.id;
  const markerEnabled: string = publicRuntimeConfig.marker.enabled;
  const markerId: string = publicRuntimeConfig.marker.id;

  // console.log("queryParsed: ", queryParsed);

  // set cookies from url params
  for (const [key, value] of Object.entries(queryParsed)) {
    const valueString = value as string;
    if (Object.keys(keyMapping).indexOf(key) > -1) {
      setCookie(null, key, valueString, {
        path: "/",
        maxAge: 60 * 60 * 24
      });
    }
  }

  const dataLayerPush = () => {
    window.dataLayer?.push({
      event: "pageview",
      page_title: document.title,
      page_location: document
        .querySelector("link[rel='canonical']")
        ?.getAttribute("href")
    });
  };

  useEffect(() => {
    // track starting referrer url
    if (Object.keys(keyMapping).indexOf("referrer_url") > -1) {
      setCookie(null, "referrer_url", window.location.href, {});
    }
  }, []);

  useEffect(() => {
    // additionally, save the first visited page as cookie
    if (Object.keys(keyMapping).indexOf("landing_page") > -1) {
      if (!("landing_page" in cookies)) {
        setCookie(null, "landing_page", window.location.href, {});
      }
    }

    const handleRouteChangeStart = () => {
      // additionally, track previous url
      if (Object.keys(keyMapping).indexOf("referrer_url") > -1) {
        setCookie(null, "referrer_url", window.location.href, {});
      }

      // clear out previously viewed formids
      if (window.MktoForms2 && window.MktoForms2._formIds) {
        window.MktoForms2._formIds = [];
      }
    };

    // dataLayer push on routing change
    const handleRouteChangeComplete = () => {
      if (gtmEnabled === "true" && gtmId) {
        dataLayerPush();
      }
    };
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeComplete", handleRouteChangeStart);
    };
  }, [router, gtmEnabled, gtmId, cookies]);

  useUrlHashActions();

  return (
    <>
      <Seo entry={entry} pageNumber={pageNumber} />

      <Head>
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://pi.pardot.com" />
        <link rel="preconnect" href="https://assets.calendly.com" />
        <link
          rel="preconnect"
          href={`http:${publicRuntimeConfig.marketo.baseUrl}`}
        />
        <link
          rel="preconnect"
          href={`https:${publicRuntimeConfig.marketo.baseUrl}`}
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-Black.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-Bold.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-BoldItalic.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-Medium.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-MediumItalic.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-Book.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/CircularStd-BookItalic.otf"
          as="font"
          type="font/otf"
          crossOrigin="anonymous"
        />
      </Head>

      <Script
        src="https://cdn.optimizely.com/js/23605861077.js"
        strategy="afterInteractive"
      />

      {gtmEnabled === "true" && gtmId && (
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      )}

      {markerEnabled === "true" && markerId && (
        <>
          <Script id="marker-config" strategy="afterInteractive">
            {`
              window.markerConfig = {
                destination: '${markerId}',
                source: 'snippet'
              };
            `}
          </Script>
          <Script id="marker-init" strategy="afterInteractive">
            {`
              !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
            `}
          </Script>
        </>
      )}

      {children}

      <Modal modals={modals} />

      <Script
        src={`${publicRuntimeConfig.marketo.baseUrl}/js/forms2/js/forms2.js`}
        strategy="afterInteractive"
      />
      <Script
        src="https://assets.calendly.com/assets/external/widget.js"
        strategy="afterInteractive"
      />

      <Stylesheet
        href={`https:${publicRuntimeConfig.marketo.baseUrl}/js/forms2/css/forms2.css`}
        prefetch={true}
      />
      <Stylesheet
        href={`https:${publicRuntimeConfig.marketo.baseUrl}/js/forms2/css/forms2-theme-simple.css`}
        prefetch={true}
      />
      <Stylesheet href="https://assets.calendly.com/assets/external/widget.css" />
    </>
  );
}

export default Layout;
